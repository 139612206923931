<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <material-card icon="mdi-currency-usd" icon-small color="secondary">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="products"
                :items-per-page="5"
                :search="search"
              >
                <template v-slot:[`item.productName`]="{ item }">
                  <!-- <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        style="cursor: pointer"
                        class="blue--text"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          $router.push(
                            `admin-products-prices/${item.productId}`
                          )
                        "
                      >
                        {{ item.productName }}
                      </span>
                    </template>
                    <span>Price History</span>
                  </v-tooltip> -->
                  {{ item.productName }}
                </template>

                <!-- <template v-slot:[`item.productName`]="{ item }">
                  <span>{{ item.productName }}</span>
                </template> -->

                <template v-slot:[`item.subDepartmentId`]="{ item }">
                  <span>{{ getSubDeptName(item.subDepartmentId) }}</span>
                </template>
                <template v-slot:[`item.unitId`]="{ item }">
                  <span>{{ getUnitName(item.unitId) }}</span>
                </template>
                <template v-slot:[`item.lastPrice`]="{ item }">
                  <span>{{ getLastPrice(formatPrice(item.lastPrice)) }}</span>
                </template>

                <template v-slot:[`item.lastPriceUpdatedTime`]="{ item }">
                  <span>{{ item.lastPriceUpdatedTime | localDate }}</span>
                </template>

                <template v-slot:[`item.retailPrice`]="{ item }">
                  <span>${{ formatPrice(item.retailPrice) }}</span>
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dense
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="updatePrice(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit Price</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.discountPrice`]="{ item }">
                  <span>${{ formatPrice(item.discountPrice) }}</span>
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dense
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="updateDiscountPrice(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit Discount Price</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.editedFront`]="{ item }">
                  <v-icon v-if="item.editedFront === true" color="primary">
                    mdi-cash-check
                  </v-icon>
                </template>

                <!-- <template v-slot:[`item.history`]="{ item }">
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          $router.push(
                            `admin-products-prices/${item.productId}`
                          )
                        "
                      >
                        mdi-clipboard-text-clock
                      </v-icon>
                    </template>
                    <span>Price History</span>
                  </v-tooltip>
                </template> -->
                <!-- <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="updatePrice(item)"
                      >
                        mdi-pencil-circle
                      </v-icon>
                    </template>
                    <span>Edit Price</span>
                  </v-tooltip>
                </template> -->
              </v-data-table>
            </material-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="500px" origin="top right">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialog = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline"> Change Price</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="editedItemForm">
              <v-row>
                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    readonly
                    v-model="editedItem.lastPrice"
                    label="Previous Price"
                    disabled
                    outlined
                  />
                </v-col>
                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    readonly
                    v-model="editedItem.retailPrice"
                    label="Current Price"
                    disabled
                    outlined
                  />
                </v-col>

                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    v-model="productPrice.price"
                    label="New Price"
                    outlined
                    clearable
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDiscount" max-width="500px" origin="top right">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialogDiscount = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline"> Change Discount Price</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="discountForm">
              <v-row>
                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    v-model="editedItem.retailPrice"
                    label="Price"
                    disabled
                    outlined
                    clearable
                    type="number"
                  />
                </v-col>

                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    v-model="editedItem.discountPrice"
                    label="Discount Price"
                    outlined
                    clearable
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="dialogDiscount = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveDiscount"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}

.row1 {
  margin-top: -25px;
}

.itemsLayout {
  height: 415px;
  overflow: hidden;
  overflow-y: auto;
}

.addbutton {
  float: right;
}

.temp {
  background-color: rgb(255, 255, 255);
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";
var moment = require("moment");

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,

      dialog: false,
      dialogDiscount: false,

      search: "",

      headers: [
        //  { text: "", value: "history" },
        { text: "Name", value: "productName" },
        { text: "Category", value: "subDepartmentId" },
        { text: "Unit", value: "unitId" },
        { text: "Previous Price", value: "lastPrice" },

        { text: "Current Price", value: "retailPrice" },
        { text: "Discount Price", value: "discountPrice" },
        { text: "Updated On", value: "lastPriceUpdatedTime" },
        { text: "", value: "editedFront" },

        // { text: "", value: "actions" },
      ],

      editedIndex: "",
      editedItem: {
        lastPriceUpdatedTime: "",
        lastPrice: "",
        retailPrice: "",
        discountPrice: "",
      },

      defaultItem: {
        lastPriceUpdatedTime: "",
        lastPrice: "",
        retailPrice: "",
        discountPrice: "",
      },

      productPrice: {
        toDate: "",
        price: "",
        productId: "",
      },

      defaultProductPrice: {
        toDate: "",
        price: "",
        productId: "",
      },

      page: 1,
      pageCount: null,
      start: 0,
      end: 11,
      dataFiltered: [],

      products: [
        // {
        //   productId: "2",
        //   lastPriceUpdatedTime: "2022-08-25T06:07:45.667Z",
        //   lastPrice: "6",
        //   retailPrice: "5"
        // }
      ],
      subDepartments: [],
      units: [],
      discountPrice: "",
    };
  },
  filters: {
    localDate: function (date) {
      var localDate = moment.utc(date).local().format("MMM Do YYYY - hh:mm A");
      return date != null ? localDate : " Not Yet";
    },
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    page: function () {
      this.changePage();
    },
  },

  created() {
    this.getDataFromApi();
  },

  methods: {
    getSubDeptName(id) {
      var result = this.subDepartments.find((o) => {
        return o.subDepartmentId === id;
      });
      return result ? result.subDepartmentName : "Null";
    },

    getUnitName(id) {
      var result = this.units.find((o) => {
        return o.unitID === id;
      });
      return result ? result.unitName : "Null";
    },

    getLastPrice(val) {
      return val != 0.0 ? "$" + val : "Not Changed";
    },

    validate() {
      if (this.productPrice.price != "" && this.productPrice.price != null) {
        var today = Date();

        today = moment.utc().local(today).format("YYYY-MM-DDThh:mm:ss");

        //console.log("Today", today);

        this.editedItem.lastPrice = this.editedItem.retailPrice;
        this.editedItem.lastPriceUpdatedTime = today;
        this.editedItem.retailPrice = this.productPrice.price;
        this.productPrice.productId = this.editedItem.productId;
        this.productPrice.toDate = today;

        // console.log("  this.editedItem", this.editedItem);
        //  console.log("   this.productPrice", this.productPrice);
        this.save();
      } else {
        this.$toast.error("New Price Required", "Error", {
          position: "topRight",
        });
      }
    },
    save() {
      var itemId = this.editedItem.productId;
      this.isLoading = true;
      this.$http
        .put("/ProductMaster/" + itemId, this.editedItem)
        .then((response) => {
          if (response.status == "200") {
            this.products.splice(this.editedIndex, 1, this.editedItem);

            console.log("Worked");
            this.products[this.editedIndex].editedFront = true;
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });

            //editedFront

            this.$http
              .post("ProductPrice", this.productPrice)
              .then((response) => {
                if (response.status == "200") {
                  this.dialog = false;
                  this.isLoading = false;
                } else {
                  this.isLoading = false;
                  this.$toast.error(response.data.message, "Error", {
                    position: "topRight",
                  });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                if (error) {
                  this.$toast.error(error.response, "Error", {
                    position: "topRight",
                  });
                }
              });
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error) {
            this.$toast.error(error.response, "Error", {
              position: "topRight",
            });
          }
        });
    },

    saveDiscount() {
      console.log(this.editedItem);
      if (this.editedItem.discountPrice != "") {
        var itemId = this.editedItem.productId;
        this.isLoading = true;
        this.$http
          .put("/ProductMaster/" + itemId, this.editedItem)
          .then((response) => {
            if (response.status == "200") {
              this.products.splice(this.editedIndex, 1, this.editedItem);
              this.products[this.editedIndex].editedFront = true;
              this.isLoading = false;

              this.dialogDiscount = false;
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.isLoading = false;
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error) {
              this.$toast.error(error.response, "Error", {
                position: "topRight",
              });
            }
          });
      } else {
        this.$toast.error("Discount Price Required", "Error", {
          position: "topRight",
        });
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    updatePrice(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.products.indexOf(item);
      this.dialog = true;
    },
    updateDiscountPrice(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = this.products.indexOf(item);
      this.dialogDiscount = true;
    },

    getDataFromApi() {
      this.isLoading = true;
      this.$http
        .get("/ProductMaster")
        .then((response) => {
          this.products = response.data.data;
          this.products = this.products.reverse();

          for (var i = 0; i < this.products.length; i++) {
            // console.log(this.products.length);
            //console.log(this.products[i]);
            this.products[i].editedFront = false;
          }

          this.changePage();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });

      this.$http.get("/Unites/").then((response) => {
        this.units = response.data.data;
      });
      this.$http.get("/SubDepartment/").then((response) => {
        this.subDepartments = response.data.data;
      });
    },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      var count = this.products.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.products.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.isLoading = false;
    },

    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.productPrice = Object.assign({}, this.defaultProductPrice);
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
    },
  },
};
</script>
